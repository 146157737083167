import { Accordion, AccordionItem, AccordionItemContent, AccordionItemHeader, Box } from "@edgetier/client-components";
import { Button, SpinnerUntil } from "@edgetier/components";
import { doNothing } from "@edgetier/utilities";
import { faCheck, faChevronRight, faQuestionCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { memo, useCallback, useMemo } from "react";

import AddItemButton from "./add-item-button";
import SelectBulkButtons from "./select-bulk-buttons";
import SelectListTitle from "./select-list-title";
import { IProps } from "./select-menu.types";
import SelectSelectedList from "./select-selected-list";
import { APPLY_BUTTON_TEXT, CANCEL_BUTTON_TEXT, MINIMUM_TAGS_TO_OPEN_ACCORDIONS } from "~/select.constants";
import { calculateGroupedFlatItemIndex } from "./select-menu.utilitites";
import "./select-menu.scss";

/**
 * Menu to allow users to choose one or more items from a list.
 * @param props.addItemMenu            A menu for adding an item to the select.
 * @param props.children               Optional method to render items.
 * @param props.close                  Method to close the menu.
 * @param props.description            Description of items to be selected.
 * @param props.disableMenuItems       Whether the menu items should be disabled or not.
 * @param props.getGroup               Getter for an item's group name.
 * @param props.getLabel               Getter for an item's label.
 * @param props.getValue               Getter for an item's value.
 * @param props.getIcon                Getter for an item's icon.
 * @param props.isLoading              Optional loading state of the items.
 * @param props.isSingleSelect         Whether one or more items can be selected.
 * @param props.message                An optional message to show in the select menu.
 * @param props.noItemsFoundLabel      Label to show when no items are found.
 * @param props.onSelectItems          Handler when items are selected.
 * @param props.onMassSelect           Handler when all items are selected or deselected.
 * @param props.onInputChange          A function that gets called when the select's input value changes.
 * @param props.highlightedIndex       Index of the highlighted item.
 * @param props.getItemProps           Getter for the props of an item.
 * @param props.getSelectedItemProps   Getter for the props of a selected item.
 * @param props.removeSelectedItem     Method to remove a selected item.
 * @param props.selectedItems          Items selected.
 * @param props.setSelectedItems       Method to set the selected items.
 * @param props.notSelectedItems       Items not selected.
 * @param props.selectedValues         Values selected before opening the menu.
 * @returns Menu of items to choose.
 */
const SelectMenu = <IItem extends {}, IValue extends {} = string>({
    allItems,
    addItemMenu,
    children,
    close,
    description,
    disableMenuItems = false,
    getGroup,
    getLabel,
    getValue,
    getIcon,
    isCompact = false,
    isLoading = false,
    isSingleSelect = false,
    message,
    noItemsFoundLabel,
    onSelectItems,
    onMassSelect = doNothing,
    highlightedIndex,
    getItemProps,
    getSelectedItemProps,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
    notSelectedItems,
    selectedValues,
    menuListProps,
    isMultipleSelectCheckbox,
    enableAutoFocus = true,
    ...other
}: IProps<IItem, IValue>) => {
    const isMobileMultiSelect = isMultipleSelectCheckbox && !isSingleSelect;

    const { flatItems, flatItemIndexMap, groupedItemsWithIndex } = useMemo(
        () => calculateGroupedFlatItemIndex(notSelectedItems, getGroup),
        [notSelectedItems, getGroup]
    );

    const { flatItemIndexMap: mobileFlatItemIndexMap } = useMemo(() => {
        // Calculate grouped flat items for filtered items
        return calculateGroupedFlatItemIndex(notSelectedItems, getGroup);
    }, [getGroup, notSelectedItems]);

    /**
     * Select some items and close the menu.
     * @param items Newly selected items.
     */
    const selectAndClose = (items: IItem[]) => {
        onSelectItems(items.map(getValue), items);
        close();
    };

    /**
     * Close the menu and return the selected options.
     */
    const onApply = (currentlySelectedItems: IItem[]) => {
        selectAndClose(currentlySelectedItems);
    };

    /**
     * Select all items.
     */
    const onSelectAll = useCallback(() => {
        setSelectedItems(selectedItems.concat(notSelectedItems));
        onMassSelect();
    }, [notSelectedItems, onMassSelect, selectedItems, setSelectedItems]);

    /**
     * Clear all items.
     */
    const onSelectNone = useCallback(() => {
        setSelectedItems([]);
        onMassSelect();
    }, [onMassSelect, setSelectedItems]);

    const hasMultipleGroups = useMemo(() => Object.keys(groupedItemsWithIndex).length > 1, [groupedItemsWithIndex]);

    /**
     * Create a list item to be shown in the menu.
     * @param item  Item to be shown.
     * @param index Index of the item.
     */
    const createListItem = (item: IItem, index: number) => {
        const value = getValue(item);
        const key = typeof value === "object" ? Object.values(value).join() : value.toString();
        const isSelected = selectedItems.includes(item);
        return (
            <li key={key} data-testid={[other?.["data-testid"], key].filter(Boolean).join("_")}>
                {!hasMultipleGroups &&
                    typeof getGroup === "function" &&
                    (index === 0 || getGroup(item) !== getGroup(notSelectedItems[index - 1])) && (
                        <div aria-label={getGroup(item)} className="select-menu__group-title">
                            {getGroup(item)}
                        </div>
                    )}
                <div
                    aria-label={getLabel(item)}
                    className={classNames("select-menu__option", {
                        "select-menu__option--is-highlighted": index === highlightedIndex,
                        "select-menu__option--is-disabled": disableMenuItems,
                    })}
                    {...getItemProps({ item, index: index })}
                >
                    <div className="select-menu__option__label">
                        {typeof getIcon === "function" ? (
                            <span className="select-menu__option__label__icon">
                                <FontAwesomeIcon icon={getIcon(item)} />
                            </span>
                        ) : null}
                        {isMobileMultiSelect ? (
                            <div className="select-menu__option-checkbox-container">
                                <input type="checkbox" checked={isSelected} onChange={() => {}} />
                                {typeof children === "function" ? children(item) : getLabel(item)}
                            </div>
                        ) : (
                            <>{typeof children === "function" ? children(item) : getLabel(item)}</>
                        )}
                    </div>
                    {!isSingleSelect && !isMultipleSelectCheckbox && <FontAwesomeIcon icon={faChevronRight} />}
                </div>
            </li>
        );
    };

    return (
        <Box
            className={classNames("select-menu", {
                "select-menu--is-multiple-select": !isSingleSelect && !isMultipleSelectCheckbox,
                "select-menu--is-single-select": isSingleSelect,
            })}
        >
            <div className="select-menu__lists">
                <div className="select-menu__list select-menu__list--not-selected">
                    {!isSingleSelect && !isMultipleSelectCheckbox && (
                        <SelectListTitle count={notSelectedItems.length} title="Not Selected" />
                    )}

                    <div className="select-menu__options">
                        {typeof message !== "undefined" && (
                            <div className="select-menu__message">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                {message}
                            </div>
                        )}

                        {!isCompact && isSingleSelect && selectedItems.length > 0 && (
                            <div className="select-menu__selected-item">
                                {typeof getIcon === "function" ? (
                                    <span className="select-menu__option__label__icon">
                                        <FontAwesomeIcon icon={getIcon(selectedItems[0])} />
                                    </span>
                                ) : null}

                                <>
                                    {typeof children === "function"
                                        ? children(selectedItems[0])
                                        : getLabel(selectedItems[0])}
                                </>
                            </div>
                        )}

                        {notSelectedItems.length === 0 && !isLoading && (
                            <div className="select-menu__options--empty">
                                {typeof noItemsFoundLabel === "undefined"
                                    ? `No ${description}s found`
                                    : noItemsFoundLabel}
                            </div>
                        )}

                        <ul className="select-menu__list" {...menuListProps}>
                            <SpinnerUntil data={[]} isReady={!isLoading}>
                                {!hasMultipleGroups ? (
                                    Array.from(
                                        isMobileMultiSelect
                                            ? mobileFlatItemIndexMap.entries()
                                            : flatItemIndexMap.entries()
                                    ).map(([item, index]) => createListItem(item, index))
                                ) : (
                                    <>
                                        {Object.entries(groupedItemsWithIndex).map(([group, items]) => (
                                            <Accordion key={group}>
                                                <AccordionItem
                                                    canOpen
                                                    isOpen={flatItems.length < MINIMUM_TAGS_TO_OPEN_ACCORDIONS}
                                                    key={group}
                                                >
                                                    <AccordionItemHeader>
                                                        <div aria-label={group}>{group}</div>
                                                    </AccordionItemHeader>
                                                    <AccordionItemContent>
                                                        {items.map(({ item }, index) => {
                                                            const flatItemIndex = flatItemIndexMap.get(item);
                                                            return createListItem(item, flatItemIndex ?? index);
                                                        })}
                                                    </AccordionItemContent>
                                                </AccordionItem>
                                            </Accordion>
                                        ))}
                                    </>
                                )}
                            </SpinnerUntil>
                        </ul>
                    </div>

                    {typeof addItemMenu !== "undefined" && (
                        <div className="select-menu__add-item">
                            <AddItemButton addItemMenu={addItemMenu} />
                        </div>
                    )}
                </div>

                {!isSingleSelect && !isMultipleSelectCheckbox && (
                    <SelectBulkButtons
                        isDisabled={disableMenuItems}
                        notSelectedItemsCount={notSelectedItems.length}
                        onSelectAll={onSelectAll}
                        onSelectNone={onSelectNone}
                        selectedItemsCount={selectedItems.length}
                    />
                )}

                {!isSingleSelect && !isMultipleSelectCheckbox && (
                    <SelectSelectedList
                        getGroup={getGroup}
                        getLabel={getLabel}
                        getSelectedItemProps={getSelectedItemProps}
                        getValue={getValue}
                        isLoading={isLoading}
                        removeSelectedItem={removeSelectedItem}
                        selectedItems={selectedItems}
                    >
                        {children}
                    </SelectSelectedList>
                )}
            </div>

            {!isSingleSelect && (
                <div className="select-menu__controls">
                    {!isMultipleSelectCheckbox && (
                        <Button icon={faTimes} onClick={() => close()} styleName="neutral">
                            {CANCEL_BUTTON_TEXT}
                        </Button>
                    )}

                    <Button
                        disabled={isLoading}
                        icon={faCheck}
                        onClick={() => onApply(selectedItems)}
                        styleName={isMultipleSelectCheckbox ? "primary" : "positive"}
                        className={
                            isMultipleSelectCheckbox
                                ? "select-menu__controls-mobile-apply"
                                : "select-menu__controls-apply"
                        }
                    >
                        {APPLY_BUTTON_TEXT}
                    </Button>
                </div>
            )}
        </Box>
    );
};

export default memo(SelectMenu) as typeof SelectMenu;
